import React, { Component } from 'react';
import { MyMapComponent } from './Map';
import Axios from 'axios';

const UpdateIntervalMS = 1000 * 10;

export class DeviceLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {
        lat: 0,
        lng: 0,
      },
    };
    this.updatePosition = this.updatePosition.bind(this);
  }

  getDeviceId() {
    return this.props.match.params.deviceId;
  }

  async updatePosition() {
    const deviceId = this.getDeviceId();
    // This is for the testflight
    // const { data } = await Axios.get(`http://fursponse.com/devices/${deviceId}/position`);
    const { data } = await Axios.get(`https://api.furtrieve.com/devices/${deviceId}/position`);
    this.setState({ position: data });
  }

  componentDidMount() {
    this.updatePosition();
    setInterval(this.updatePosition, UpdateIntervalMS);
  }

  render() {
    return (
      <MyMapComponent
        position={this.state.position}
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCHnsTaGUmaTWmGwWvqVZOzxUssnzp8ddM"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    );
  }
}
