import React from 'react';

import { DeviceLocation } from './DeviceLocation';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'

import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Route path='/devices/:deviceId' component={DeviceLocation} />
      </Router>
    </div>
  );
}

export default App;
