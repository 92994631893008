import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

export const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={15}
    center={props.position}
  >
    {props.isMarkerShown && <Marker position={props.position} />}
  </GoogleMap>
))